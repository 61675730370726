import FooterBottomStyleWrapper from "./FooterBottom.style";
import data from "../../../../../assets/data/socialProfile";
import backToTopIcon from "../../../../../assets/images/icon/back_to_top.svg";
import footerShapesLeft from "../../../../../assets/images/icon/footer_shapes_left.png";
import footerShapesRight from "../../../../../assets/images/icon/footer_shapes_right.png";
import cc0mferSig from "../../../../../assets/images/cc0mfer_sig.png";

const FooterBottom = () => {
  const year = new Date().getFullYear();

 
};

export default FooterBottom;
