import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import { FaTwitter, FaWallet } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";

import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  const { walletModalHandle } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };
  return (
    <MobileMenuStyleWrapper className="tbnft_mobile_menu">
      <div className="tbnft_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="tbnft_logo" src={logo} alt="tbnft logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="tbnft_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <a href="#home" onClick={() => mobileMenuhandle()}>Home</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#about" onClick={() => mobileMenuhandle()}>About</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#faq" onClick={() => mobileMenuhandle()}>FAQ</a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="https://twitter.com/TiltedBunnies" rel="noreferrer" target="_blank">
            <FaTwitter />
          </a>
        </div>
        {/* <Button
          sm
          variant="hovered"
          className="connect_btn"
          onClick={() => walletModalHandle()}
        >
          <FaWallet /> Connect
        </Button> */}
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
