const data = [ 
    {
      widgetName: "Site map",
      items: [
        {
          title: "Home",
          url: "/",
        },
        {
          title: "About",
          url: "#about",
        },
        {
          title: "FAQ",
          url: "#faq",
        }
      ],
    },
    // {
    //   widgetName: "More Goodies",
    //   items: [
    //     {
    //       title: "Terms + Conditions",
    //       url: "#",
    //     },
    //   ],
    // },

]

export default data;
