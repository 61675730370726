const data = [
  {
    title: "What is Tilted Bunnies?",
    text: "1,555 bunnies fed up with this market - they were aggressively generated for degens. Oh, and of course - on the Ethereum blockchain. Market got you tilted? Grab a bunny and come tilt with us!",
  },
  {
    title: "What will I get for holding tilted bunnies?",
    text: "There are no promises. The mint is meant to distribute these tilted bunnies to those who identify with our community at the current market",
  },
  {
    title: "So where's the roadmap, buddy?",
    text: "What roadmap? It's web3 - we can do whatever we want. Join our community and pitch ideas, we will give you ample opportunity to do so through DAO votes announced in our <a href='https://twitter.com/TiltedBunnies' target='_blank'>Twitter</a>.",
  },  
  {
    title: "Anything else I should get hype about?",
    text: "Sometimes we like to make things interesting.  Keep an eye for the fun traits. Look for the hints on the rest of the website for what's to come ;).",
  },
];

export default data;
