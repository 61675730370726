// import { useState } from "react";
// import { useModal } from "../../../../utils/ModalContext";
import moment from 'moment';

import CountdownTimer from "react-component-countdown-timer";

import Button from "../../../../common/button";

import imgGrid from "../../../../assets/images/banner/grid_tb.png";
// import bannerThumb1 from "../../../../assets/images/banner/1.png";
// import bannerThumb2 from "../../../../assets/images/banner/2.png";
// import bannerThumb3 from "../../../../assets/images/banner/3.png";
import BannerStyleWrapper from "./Banner.style";

const now = moment.utc(new Date()); //todays date
const end = moment.utc('2022-11-28T16:00:00'); // sale date
const duration = moment.duration(now.diff(end));
const seconds = Math.abs(duration.asSeconds());

const Banner = () => {
  // const { mintModalHandle } = useModal();
  // const [count, setCount] = useState(1);

  const settings = {
    count: seconds,
    showTitle: true, 
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "",
    hourTitle: "",
    minuteTitle: "",
    secondTitle: "", 
    id: "countdownwrap",
  };
  
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <BannerStyleWrapper className="tbnft_v3_baner_sect" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="banner-image-area3">
              <img
                className="banner-image banner-image1"
                src={imgGrid}
                alt="tbnft banner thumb"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-conent3">
              <h4 className="banner-subtitle text-uppercase">
                Status: <span id="banner-status">Collecting Entries</span>
              </h4>
              <h1 className="banner-title text-uppercase">Join the Tilted DAO</h1>
              <div className="tbnft_v3_timer">
                <h5 className="text-uppercase">Mint Opens In</h5>
                <div className="timer timer_1">
                  <CountdownTimer {...settings} />
                </div>
              </div>
              <div className="banner-count-inner d-flex align-items-center">
                <div className="tbnft_v3_baner_buttons">
                  <Button lg variant="mint" onClick={() => openInNewTab('https://www.premint.xyz/tiltedbunnies/')}>
                    MINT RAFFLE
                  </Button>
                </div>
              </div>
              <div className="banner-bottom-text text-uppercase">
              TOTAL SUPPLY: 1,555 Tilted Bunnies<br/><br/>

              1,500 bunnies that grant DAO membership can be minted for 0.01 ETH<br/><br/>

              The team will allocate 55 bunnies for the DAO<br/><br/>

              All Royalties will go to the DAO<br/><br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
