import aboutThumb1 from "../../images/blog/1m.png";
import aboutThumb2 from "../../images/blog/1d.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "A degen play for the ones that are still here. 1,555 hand-picked degenerate looking bunnies from a batch of 10k+ combinations. Many traits are inspired by popular projects and characters - we firmly believe you will find a bunny that resonates with you.",
  aboutDescription2:
    "Phase 1: Tilted bunnies will be available to selected raffle winners for 0.01 ETH",
  aboutDescription3:
    "Phase 2: Starts 2 hours after Phase 1, any remaining bunnies will be available to waitlisted for 0.01 ETH",
  aboutDescription4:
    "Phase 3: Starts 2 hours after Phase 2, any remaining bunnies will be available to the public for 0.015 ETH",
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      // name: "cc0bunnies",
      // role: "CTO - Chief Tilting Officer",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      // name: "kingbunny",
      // role: "CEO - Chief ETH Officer",
    },
  ],
};

export default data;
