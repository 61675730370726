import {useModal} from "../utils/ModalContext"
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v1/Header";
import Banner from "../components/section/banner/v3";
import CharacterSlider from "../components/section/characterSlider/v3";
import About from "../components/section/about/v3";
// import HowToMint from "../components/section/howToMint/v2";
import FAQ from "../components/section/faq/v3";
import Footer from "../components/section/footer/v3";
// import MintNowModal from "../common/modal/mintNowModal" 
import WalletModal from "../common/modal/walletModal/WalletModal" 
const HomeV3 = () => {
  const { visibility, walletModalvisibility } = useModal();
  return (
    <>
      <Layout>
      <GlobalStyles />
      {/* {visibility && <MintNowModal />} */}
      {visibility}
      {walletModalvisibility && <WalletModal />}
        <Header />
        <Banner />
        <CharacterSlider />
        <About />
        {/* <HowToMint /> */}
        <FAQ />
        <Footer />
      </Layout>
    </>
  );
};

export default HomeV3;
