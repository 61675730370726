import SectionTitle from "../../../../common/sectionTitle";

import data from "../../../../assets/data/about/dataV2";

import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2, aboutDescription3, aboutDescription4, artists } = data;
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="TILTED BUNNIES" subtitle="Your tilted frens in the ETH blockchain" />
              <div className="v1_about_us_right_text">
                <p>{aboutDescription1}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
          <div className="v1_about_us_right_sect">
              <SectionTitle title="On Mint Day" subtitle="The mint will have 3 phases" />
              <div className="v1_about_us_right_text">
                <p>{aboutDescription2}</p>
                <p>{aboutDescription3}</p>
                <p>{aboutDescription4}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
