import character1 from "../../images/nft/1.png";
import character2 from "../../images/nft/2.png";
import character3 from "../../images/nft/3.png";
import character4 from "../../images/nft/4.png";
import character5 from "../../images/nft/5.png";
import character6 from "../../images/nft/6.png";

const data = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character3,
];

export default data;
